// AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg
// https://tomchentw.github.io/react-google-maps/#usage--configuration
// https://console.developers.google.com/apis/credentials?project=i-play-red-1488729580925&authuser=1
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'
import MapSearch from './MapSearch'

const MapInput = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCse2jLveCwlTP7KuZhnYgoojGUOqlfBQI&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `150px` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `150px` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        lat: '',
        lng: '',
        onMapMounted: ref => {
          refs.map = ref;
        },
        setSomething: () => {
          var loc
          var map = refs.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
          new google.maps.places.PlacesService(map).findPlaceFromQuery({ query: this.props.address, fields: ['formatted_address', 'geometry'] }, (data) => {
            loc = Array.isArray(data) && data[0] && data[0].geometry.location
            if (loc) {
              this.setState({ lat: loc.lat(), lng: loc.lng()})
              map.setCenter(loc);
              map.setZoom(15);
            }
          });
        }
      })
    },

    componentDidUpdate(prevProps) {
      if (this.props.address != prevProps.address) {
        this.state.setSomething()
      }
    }
  }),
  // withScriptjs,
  withGoogleMap
)(props =>
  <div>
    <input type="hidden" name="cafe[latitude]" value={props.lat}/>
    <input type="hidden" name="cafe[longitude]" value={props.lng}/>
    <GoogleMap
      ref={props.onMapMounted}
      defaultZoom={3}
      defaultCenter={{ lat: -12.043333, lng: -77.028333 }}
      defaultOptions={{
        streetViewControl: false,
        scrollwheel: false,
        mapTypeControl: false,
      }}
      >
      {props.lat && props.lng && <Marker position={{ lat: props.lat, lng: props.lng }}/>}
    </GoogleMap>
  </div>
);
export default MapInput
