import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'react-materialize'
import Image from 'react-graceful-image'
import { StaticGoogleMap, Marker } from 'react-static-google-map';
import Rater from 'react-rater';
import 'react-rater/lib/react-rater.css';
import axios from 'axios';
import YouTube from 'react-youtube';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
import { Icon } from 'react-materialize';

class Cafe extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      score: this.props.cafe.score,
      meta_score: this.props.cafe.meta.score
    }
  }

  ratingChanged = ({rating}) => {
    axios.patch('/cafes/' + this.props.cafe.id + '/rate.json', {
      rating: { score: rating }
    }).then(data => {
      var cafe = data.data
      this.setState({ score: cafe.score, meta_score: cafe.meta.score })
    })
  }

  render () {
    const style = {
      content: {
        maxWidth: '500px',
        top: null,
        left: null,
        right: null,
        bottom: null
      }
    };

    const cafe = this.props.cafe;

    return (
      <div id="cafe">
        <Row>
          <Col s={12} l={12}>
            <h3>{this.props.cafe.name}</h3>
            <address>{this.props.cafe.address}</address>
            <ul>
              {cafe.web && <li><a href={cafe.web} target="blank"><i className="fa fa-link"></i>{cafe.web.replace(/(https?:\/\/)?(www\.)?/,'')}</a></li>}
              {cafe.twitter && <li><a href={cafe.twitter} target="blank"><i className="fa fa-twitter"></i>{cafe.twitter.replace(/(https?:\/\/)?(www\.)?/,'')}</a></li>}
              {cafe.facebook && <li><a href={cafe.facebook} target="blank"><i className="fa fa-facebook"></i>{cafe.facebook.replace(/(https?:\/\/)?(www\.)?/,'')}</a></li>}
              {cafe.instagram && <li><a href={cafe.instagram} target="blank"><i className="fa fa-instagram"></i>{cafe.instagram.replace(/(https?:\/\/)?(www\.)?/,'')}</a></li>}
            </ul>
          </Col>
          <Col s={12} l={12}>
            <StaticGoogleMap size="600x400" apiKey="AIzaSyCse2jLveCwlTP7KuZhnYgoojGUOqlfBQI">
              <Marker
                location={{ lat: this.props.cafe.latitude, lng: this.props.cafe.longitude }}
                />
            </StaticGoogleMap>
          </Col>
        </Row>
        <Row>
          <Col s={6} m={6} l={6}>
            <dl className="s6 m6 l6">
              <dt>My score</dt>
              <dd><Rater rating={this.state.score} total={5} onRate={this.ratingChanged}/></dd>
            </dl>
          </Col>
          <Col s={6} m={6} l={6}>
            <dl>
              <dt>Community score</dt>
              <dd><Rater rating={this.state.meta_score} total={5} interactive={false}/></dd>
            </dl>
          </Col>
        </Row>
        {this.props.cafe.video_id
          && (<Row>
               <Col s={12} l={12}>
                 <div className="aspect16by9">
                   <YouTube videoId={this.props.cafe.video_id} opts={{ width: '100%', height: '100%'}}/>
                 </div>
               </Col>
             </Row>
        )}
      </div>
    )
  }
}

export default Cafe
