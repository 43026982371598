import React, { useReducer, useEffect } from 'react';
import Select from 'react-select'
import axios from 'axios';

function chunk(arr, len) {
  var chunks = [],
      i = 0,
      n = arr.length;

  while (i < n) {
    chunks.push(arr.slice(i, i += len));
  }

  return chunks;
}

const ACTIONS = {
  CALL_API: 'call-api',
  SUCCESS: 'success',
  ERROR: 'error'
};

const initialState = {
  data: [],
  loading: false,
  publishers: [],
  designers: [],
  languages: []
};

function releasesReducer(state = initialState, action) {
  switch(action.type) {
    case ACTIONS.CALL_API:
      return {
        ...state,
        loading: true
      }
    case ACTIONS.SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        designers: [...new Set(action.payload.data.map((r) => r.designers).reduce((arr, a) => { return [...arr, ...a] }))].map((d) => {
          return { value: d, label: d }
        }),
        publishers: [...new Set(action.payload.data.map((r) => r.publisher))].sort().map((p) => {
          return { value: p, label: p }
        }),
        languages: [...new Set(action.payload.data.map((r) => r.languages).reduce((arr, a) => { return [...arr, ...a] }))].map((l) => {
          return { value: l, label: l }
        })
      }
    default:
      return state
  }
}

// Optionally, you can do the following to avoid cluttering the global namespace:
Array.chunk = chunk;

function Releases (props) {
  const [state, dispatch] = useReducer(releasesReducer, initialState)

  useEffect(() => {
    dispatch({ type: ACTIONS.CALL_API })
    fetch()
  }, [])

  function fetch() {
    axios.get(props.url).then((response) => {
      dispatch({ type: ACTIONS.SUCCESS, payload: { data: response.data } })
    })
  }

  function handleChange(e) {
    console.log(e.target)
  }

  return (
    <div>
      {state.loading && (<span>loading</span>)}
      <Select name="publisher" options={state.publishers} onChange={handleChange}/>
      <Select name="designer" options={state.designers} onChange={handleChange}/>
      {state.data && chunk(state.data, 2).map(rs => {
        return (<div className="row">{rs.map(r => (
          <div key={r.id}>
            <div className="col s12 l2">
              <img src={r.image}/>
            </div>
            <div className="col s12 l4">
              <h4>{r.title}</h4>
              <div className="publisher">{r.publisher}</div>
              <div>{r.designers.join(', ')}</div>
              <div className="languages">
                {r.languages.map((l) => {
                  return (
                    <span>{l}</span>
                  )
                })}
              </div>
            </div>
          </div>
        ))
      }</div>)})}
    </div>
  )
}

export default Releases;
