import axios from 'axios';
import React from 'react';
import { Row, Col } from 'react-materialize';
import countryList from 'react-select-country-list'
import MapInput from './MapInput';

class CafeForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {},
      address: '',
      completed: false,
      countries: countryList().getData()
    }
  }

  onChange = (event) => {
    var fields = ['cafe[name]', 'cafe[street1]','cafe[street2]', 'cafe[city]', 'cafe[county]', 'cafe[postcode]', 'cafe[country]']
    var r = [].reduce.call(event.target.form.elements, (data, element) => {
      if (fields.indexOf(element.name) > -1) {
        data.push(element.value)
      }
      return data;
    }, []);
    this.setState({ address: r.filter(String).join(', ') })
    return true;
  }

  onSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target)
    axios
      .post('/cafes.json', data)
      .then(data => {
        this.setState({ completed: true })
      })
      .catch(error => {
        this.setState({ errors: error.response.data.errors })
      })
  }

  render () {
    return (
      <form action="/cafes" onChange={this.onChange.bind(this)} onSubmit={this.onSubmit.bind(this)}>
        <Row>
          <Col s={12} l={12}>
            <h3>Submit a new cafe</h3>
          </Col>
        </Row>
        {this.state.completed ? (
          <div>
            <p>Cafe has now been submitted, it will be reviewed and made live with 24 hours.</p>
            <p>Thanks for your submission,</p>
            <p>Kat - <a href="https://iplayred.co.uk">I Play Red</a></p>
            <div id="share"></div>
          </div>
        ) : (
          <span>
            <Row>
              <Col s={12}>
                <label>Name</label>
                <input type="text" name="cafe[name]"/>
                {this.state.errors && this.state.errors.name && <span className="error">{this.state.errors.name}</span>}
                <label>Street 1</label>
                <input type="text" name="cafe[street1]"/>
                {this.state.errors && this.state.errors.street1 && <span className="error">{this.state.errors.street1}</span>}
                <label>Street 2</label>
                <input type="text" name="cafe[street2]"/>
                {this.state.errors && this.state.errors.street2 && <span className="error">{this.state.errors.street2}</span>}
                <label>City</label>
                <input type="text" name="cafe[city]"/>
                {this.state.errors && this.state.errors.city && <span className="error">{this.state.errors.city}</span>}
                <label>County</label>
                <input type="text" name="cafe[county]"/>
                {this.state.errors && this.state.errors.county && <span className="error">{this.state.errors.county}</span>}
                <label>Postcode</label>
                <input type="text" name="cafe[postcode]"/>
                {this.state.errors && this.state.errors.postcode && <span className="error">{this.state.errors.postcode}</span>}
                <label>Country</label>
                <select name="cafe[country_code]">
                <option value="">-- SELECT</option>
                {this.state.countries.map(country => (
                  <option key="{country.value}"value={country.value}>{country.label}</option>
                ))}
                </select>
                {this.state.errors && this.state.errors.country && <span className="error">{this.state.errors.country}</span>}
              </Col>
              <Col s={12}>
                <label>Location</label>
                <MapInput address={this.state.address}/>
                {this.state.errors && this.state.errors.location && <span className="error">{this.state.errors.location}</span>}
                <label>Website</label>
                <input type="text" name="cafe[website]"/>
                {this.state.errors && this.state.errors.website && <span className="error">{this.state.errors.website}</span>}
                <label>Twitter</label>
                <input type="text" name="cafe[twitter_url]"/>
                {this.state.errors && this.state.errors.twitter_url && <span className="error">{this.state.errors.twitter_url}</span>}
                <label>Facebook</label>
                <input type="text" name="cafe[facebook_page]"/>
                {this.state.errors && this.state.errors.facebook_page && <span className="error">{this.state.errors.facebook_page}</span>}
                <label>Instagram</label>
                <input type="text" name="cafe[instagram_url]"/>
                {this.state.errors && this.state.errors.instagram_url && <span className="error">{this.state.errors.instagram_url}</span>}
              </Col>
            </Row>
            <Row>
              <Col>
                <input type="submit" className="button"/>
              </Col>
            </Row>
          </span>
        )}
      </form>
    )
  }
}

export default CafeForm;
