import React from 'react';
import PropType from 'prop-types';
import {Row, Col} from 'react-materialize'

class CountryComponent extends React.Component {
  render () {
    return (
      <Row>
        <Col s={12} m={12} l={12}>
          <form>
            <label>Country</label>
            <select onChange={this.props.onCountryChange}>
              {this.props.countries.map(country => (
                <option key={country} value={country}>{country}</option>
              ))}
            </select>
          </form>
        </Col>
      </Row>
    )
  }
}

export default CountryComponent
