import React from "react"
import PropTypes from "prop-types"

class FacebookLogin extends React.Component {
  componentDidMount() {
    document.addEventListener('FBObjectReady', this.initializeFacebookLogin);
  }

  componentWillUnmount() {
    document.removeEventListener('FBObjectReady', this.initializeFacebookLogin);
  }

  initializeFacebookLogin = () => {
    this.FB = window.FB;
    this.checkLoginStatus();
  }

  checkLoginStatus = () => {
    this.FB.getLoginStatus(this.facebookLoginHandler);
  }

  facebookLogin = () => {
    if (!this.FB) return;

    this.FB.getLoginStatus(response => {
      if (response.status === 'connected') {
        this.facebookLoginHandler(response);
      } else {
        this.FB.login(this.facebookLoginHandler, { scope: 'public_profile' });
      }
    });
  }

  facebookLoginHandler = response => {
    if (response.status == 'connected') {
      this.FB.api('/me/accounts', pages => {
        let result = {
          pages: pages
        }
        this.props.onLogin(true, result);
      });
    }
  }

  render () {
    let {children} = this.props;
    return (
      <div onClick={this.facebookLogin}>
        CLICK ME
        {children}
      </div>
    );
  }
}

export default FacebookLogin
