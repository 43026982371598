import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class CafesList extends React.Component {
  render () {
    const cafes = this.props.markers.filter(marker => {
      if (this.props.country == null || this.props.country == 'All') {
        return true
      } else {
        return marker.country == this.props.country
      }
    })
    return (
      <React.Fragment>
        <ul className="collection">
          {cafes.map(marker => (
            <li key={marker.id} className="collection-item"
              onMouseOver={() => this.props.onMarkerMouseover(marker)}
              onMouseOut={() => this.props.onMarkerMouseout(marker)}
            ><Link to={`/cafes/${marker.id}`}>{marker.name}</Link></li>
          ))}
        </ul>
      </React.Fragment>
    )
  }
}

export default CafesList;
