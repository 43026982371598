// AIzaSyC4R6AN7SmujjPUIGKdyao2Kqitzr1kiRg
// https://tomchentw.github.io/react-google-maps/#usage--configuration
// https://console.developers.google.com/apis/credentials?project=i-play-red-1488729580925&authuser=1
import React from 'react';
import PropTypes from 'prop-types';
import { compose, withProps, lifecycle } from 'recompose';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { MarkerClusterer } from 'react-google-maps/lib/components/addons/MarkerClusterer';
import { MarkerWithLabel } from 'react-google-maps/lib/components/addons/MarkerWithLabel'

const MapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCse2jLveCwlTP7KuZhnYgoojGUOqlfBQI&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        onMapMounted: ref => {
          refs.map = ref;
        },
        setBounds: () => {
          const bounds = new google.maps.LatLngBounds();
          this.props.markers.filter(marker => {
            if (this.props.country == 'All') {
              return true
            } else {
              return marker.country == this.props.country
            }
          }).forEach(marker => {
            bounds.extend(new google.maps.LatLng(marker.latitude, marker.longitude))
          })
          refs.map.fitBounds(bounds)
        }
      })
    },
    // componentDidMount(prevProps) {
    //   this.state.setBounds()
    // },
    componentDidUpdate(prevProps) {
      if (typeof(this.state.loaded) == 'undefined') {
        this.state.setBounds()
        this.setState({ loaded: true })
      }
      if (this.props.country != prevProps.country) {
        this.state.setBounds()
      }
    }
  }),
  // withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    ref={props.onMapMounted}
    defaultZoom={3}
    defaultCenter={{ lat: -12.043333, lng: -77.028333 }}
    defaultOptions={{
      streetViewControl: false,
      scrollwheel: false,
      mapTypeControl: false,
      minZoom: 2,
      maxZoom: 17
    }}
    >
    <MarkerClusterer
      averageCenter
      enableRetinaIcons
      gridSize={20}
    >
      {props.markers.map(marker => (
        <Marker
          key={marker.id}
          position={{ lat: marker.latitude, lng: marker.longitude }}
          onMouseOver={() => props.onMarkerMouseover(marker)}
          onMouseOut={() => props.onMarkerMouseout(marker)}
          onClick={(e) => props.onMarkerClick(marker)}
        >
        </Marker>
      ))}
    </MarkerClusterer>
  </GoogleMap>
);
export default MapComponent
