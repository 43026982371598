import React from 'react';
import Modal from 'react-modal';
import { Link } from 'react-router-dom';
Modal.setAppElement('#map')

class CafeModal extends React.Component {
  render () {
    const style = {
      content: {
        maxWidth: '500px',
        top: null,
        left: null,
        right: null,
        bottom: null
      }
    };

    return (
      <Modal contentLabel="Cafe Detail"
        isOpen={true}
        style={style}
        onRequestClose={this.props.onRequestClose}
        >
        <Link to="/cafes" className="close"><i className="material-icons">close</i></Link>
        {this.props.children}
      </Modal>
    );
  }
}

export default CafeModal;
