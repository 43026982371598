import React from 'react';

class IPlayRed extends React.Component {
  render () {
    return (
      <div id="by_iplay_red">
        <a href="https://iplayred.co.uk">I Play Red</a> &copy; 2016 - {(new Date).getFullYear()}
      </div>
    )
  }
}

export default IPlayRed
