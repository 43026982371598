import React from 'react';
import PropTypes from 'prop-types';

class CafePreview extends React.Component {
  constructor(props) {
    super(props)
  }

  render () {
    return (
      <div id="cafe_preview">
        <h5>{this.props.cafe.name}</h5>
      </div>
    )
  }
}

export default CafePreview
