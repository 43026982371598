import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { compose, withProps } from 'recompose';
import { withScriptjs } from 'react-google-maps';
import Cafes from './Cafes'

const App = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCse2jLveCwlTP7KuZhnYgoojGUOqlfBQI&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: `150px` }} />,
    containerElement: <div style={{ height: `150px` }} />,
    mapElement: <div style={{ height: `150px` }} />,
  }),
  withScriptjs
)(props =>
  <BrowserRouter>
    <Cafes/>
  </BrowserRouter>
)

export default App
