import React from 'react';
import axios from 'axios';
import PropTypes from 'prop-types';
import CountryComponent from './CountryComponent';
import MapComponent from './MapComponent';
import CafePreview from './CafePreview';
import Cafe from './Cafe';
import CafesList from './CafesList';
import CafeForm from './CafeForm';
import CafeModal from './CafeModal';
import { SideNav } from 'react-materialize';
import { withRouter, BrowserRouter, Route, Link, Switch } from 'react-router-dom';
import IPlayRed from './IPlayRed'

class Cafes extends React.Component {

  static propTypes = {
    match: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
  }

  constructor() {
    super();

    this.state = {
      country: 'All',
      countries: [],
      cafes: []
    };
  }

  // componentWillMount () {
  //   this.setState({ country: null, countries: [], cafes: [], cafe: null })
  // }

  componentDidMount () {
		fetch('/cafes.json')
		.then(res => res.json())
		.then(data => {
			this.setState({
        countries: [...new Set(['All'].concat(data.cafes.map(cafe => cafe.country).sort()))],
        cafes: data.cafes,
        filtered: data.cafes
      });
		});

    $("#share").jsSocials({
        showCount: false,
        showLabel: false,
        shares: [
          { share: "twitter", via: "iplayredgaming", hashtags: 'IPlayRed,BoardgameCafes' },
          "facebook",
          "googleplus",
          "pinterest",
          "email"
        ]
    });
  }

  onCountryChange = (e) => {
    this.setState({
      country: e.target.value
    });
  }

  onMarkerMouseover = (cafe) => {
    this.setState({
      cafe: cafe,
      preview: true
    })
  }

  onMarkerClick = (cafe) => {
    this.props.history.push(`/cafes/${cafe.id}`)
  }

  onRequestClose = (e) => {
    this.props.history.push(`/cafes`)
  }

  onMarkerMouseout = (cafe) => {
    this.setState({
      preview: false
    });
  }

  render () {
    return (
      <React.Fragment>
        <div id="sideNav">
          <h1>Board Game Cafes Map</h1>
          <div id="share"></div>
          <SideNav
            trigger={<a>Search / Filter</a>}
            options={{ closeOnClick: true }}
            className="navigation"
            >
            <CountryComponent countries={this.state.countries} onCountryChange={this.onCountryChange}/>
            <CafesList
              country={this.state.country}
              markers={this.state.cafes}
              onMarkerMouseover={this.onMarkerMouseover}
              onMarkerMouseout={this.onMarkerMouseout}
              onMarkerClick={this.onMarkerClick}
            />
          </SideNav>
          <span>&nbsp;-&nbsp;<Link to="/cafes/new">Submit a new Cafe</Link></span>
        </div>
        <MapComponent
          country={this.state.country}
          markers={this.state.cafes}
          onMarkerMouseover={this.onMarkerMouseover}
          onMarkerMouseout={this.onMarkerMouseout}
          onMarkerClick={this.onMarkerClick}
        />
        <Switch>
          <Route path="/cafes/country/:country_code" render={({match}) => {
            this.state.country = 'Denmark'
            return null
          }}/>
          <Route path="/cafes/new" exact={true} render={({ match }) => {
            return (
              <CafeModal onRequestClose={this.onRequestClose}>
                <CafeForm/>
              </CafeModal>
            )
          }}/>
          {this.state.cafes && this.state.cafes.length > 0 && (
            <Route path="/cafes/:id" exact={true} render={({ match }) => {
              var cafe = this.state.cafes.find(c => c.id === Number(match.params.id))
              if (typeof(cafe) == 'undefined') {
                return null;
              }
              return (
                <CafeModal onRequestClose={this.onRequestClose}>
                  <Cafe cafe={cafe}/>
                </CafeModal>
              )
            }}/>
          )}
        </Switch>
        <IPlayRed/>
      </React.Fragment>
    )
  }
}

Cafes = withRouter(Cafes);
export default Cafes;
